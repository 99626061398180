import React from 'react'
import styled from 'styled-components'

interface LabelProps {
  text?: string
  colorText? : string
}

const Label: React.FC<LabelProps> = ({ text, colorText="light" }) => (
  (colorText == "dark") ?
  <StyledLabelDark>{text}</StyledLabelDark> :
  <StyledLabel>{text}</StyledLabel>
)

const StyledLabelDark = styled.div`
  color: ${(props) =>  props.theme.color.grey[400]};
`
const StyledLabel = styled.div`
  color: ${(props) =>  props.theme.color.white};
`
export default Label
