import React from 'react'
import styled from 'styled-components'

const Card: React.FC = ({ children }) => <StyledCard>{children}</StyledCard>

const StyledCard = styled.div`
  background: rgb(171,53,117);
  background: linear-gradient(0deg, rgba(171,53,117,1) 0%, rgba(222,111,57,1) 100%);
  border-radius: 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  color: #fff;
`

export default Card
