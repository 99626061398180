import React from 'react'
import styled from 'styled-components'
import logo from '../../assets/img/R.svg'
import PLSX from '../../assets/img/PLSX.png'
import PLS from '../../assets/img/PLS.png'
import RND from '../../assets/img/RND.png'
import RNT from '../../assets/img/RNT.png'

const HeaderIcon = (props : any) =>{
  const delimiter = "-";
  let delimiterIndex = props.string.indexOf(delimiter);
  let firsLogoName = props.string.slice(0, delimiterIndex);
  let secondLogoName = props.string.slice(delimiterIndex+1);
  let firstImageValue;
  let secondImageValue;

    switch(firsLogoName){
      case "PLS":
        firstImageValue = PLS;
      break;
      case "PLSX":
        firstImageValue = PLSX;
      break;
      case "RND":
        firstImageValue = RND;
      break;
      case "RNT":
        firstImageValue = RNT;
      break;
      default:
        firstImageValue = PLS;
    }
 
    switch(secondLogoName){
      case "PLS":
        secondImageValue = PLS;
      break;
      case "PLSX":
        secondImageValue = PLSX;
      break;
      case "RND":
        secondImageValue = RND;
      break;
      case "RNT":
        secondImageValue = RNT;
      break;
      default:
        secondImageValue = PLS;
    }

    return(
      <IconHeaderWrap>
        <StyledFirstIcon>
          <img src={firstImageValue} />
        </StyledFirstIcon>
        <StyledSecondIcon>
          <img className='farmCardBackLogo' src={secondImageValue} />
        </StyledSecondIcon>
      </IconHeaderWrap>
    )
}
const IconHeaderWrap = styled.div`
  position:relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px
`

const StyledFirstIcon = styled.div`
    margin: 10px 0 0 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
`

const StyledSecondIcon = styled.div`
position: absolute;
top: 10px;
left: 15px;
z-index: 0;
display: flex;
`


export default HeaderIcon;