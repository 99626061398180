import React, { useState, useEffect } from 'react'
import CountUp from 'react-countup'

import styled from 'styled-components'

interface ValueProps {
  value: string | number
  decimals?: number
  colorText? : string
}

const Value: React.FC<ValueProps> = ({ value, decimals , colorText}) => {
  const [start, updateStart] = useState(0)
  const [end, updateEnd] = useState(0)

  useEffect(() => {
    if (typeof value === 'number') {
      updateStart(end)
      updateEnd(value)
    }
  }, [value])

  return (
     (colorText == "dark") ? 
      <StyledValueDark>
      {typeof value == 'string' ? (
        value
      ) : (
        <CountUp
          start={start}
          end={end}
          decimals={
            decimals !== undefined ? decimals : end < 0 ? 4 : end > 1e5 ? 0 : 3
          }
          duration={1}
          separator=","
        />
      )}
    </StyledValueDark>
     : <StyledValue>
          {typeof value == 'string' ? (
            value
          ) : (
            <CountUp
              start={start}
              end={end}
              decimals={
                decimals !== undefined ? decimals : end < 0 ? 4 : end > 1e5 ? 0 : 3
              }
              duration={1}
              separator=","
            />
          )}
        </StyledValue> 
  )
}

const StyledValue = styled.div`
  font-family: 'Roboto Mono', monospace;
  color: ${(props) =>  props.theme.color.white};
  font-size: 36px;
  font-weight: 700;
`

const StyledValueDark = styled.div`
  font-family: 'Roboto Mono', monospace;
  color: ${(props) => props.theme.color.grey[600]};
  font-size: 36px;
  font-weight: 700;
`

export default Value
