import React from 'react'

interface SushiIconProps {
  size?: number
  v1?: boolean
  v2?: boolean
  v3?: boolean
  children: string
}

const SushiIcon: React.FC<SushiIconProps> = ({ size = 36, v1, v2, v3 , children}) => (
  <img src={children} alt="" />
)

export default SushiIcon
