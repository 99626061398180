import React from 'react'
import styled from 'styled-components'
import logo from '../../assets/img/logo-big.svg'
import Button from '../../components/Button'
import Container from '../../components/Container'
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import Spacer from '../../components/Spacer'
import Balances from './components/Balances'

const Home: React.FC = () => {
  return (
    <Page>
      <PageHeader
        icon={logo}
        title="Diamond Mine is Ready"
        subtitle="Stake PulseX LP tokens to claim Rentomania Diamonds!"
      />

      <Container>
        <Balances />
      </Container>
      <Spacer size="lg" />
      {/* <StyledInfo>
        🏆<b>Pro Tip</b>: JUICY-ETH LP token pool yields 4.8x more token rewards
        per block.
      </StyledInfo> */}
      <Spacer size="lg" />
      <div
        style={{
          margin: '0 auto',
        }}
      >
        <Button text="See the Menu" to="/farms" variant="secondary" />
      </div>
    </Page>
  )
}

const StyledInfo = styled.h3`
  color: ${(props) => props.theme.color.white};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;

  > b {
    color: ${(props) => props.theme.color.grey[600]};
  }
`

export default Home
